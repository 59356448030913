import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

import CartContext from './Context/CartContext'

class Header extends Component {
  state = {
    open: false
  }

  onToggle(e) {
    e.preventDefault()

    const newState = this.state.open ? false : true

    this.setState({ open: newState })
  }

  render() {
    const { siteTitle, display } = this.props

    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            site {
              siteMetadata {
                title
                options {
                  eshop
                  vouchers
                }
              }
            }
          }
        `}
        render={data => (
          <CartContext.Consumer>
            {({ cart }) => {
              return (
                <nav id="topnav" className={`navbar navbar-expand-md fixed-top navbar-light text-xs-center fader ${display ? 'on' : 'off'}`}>
                  <Link className="navbar-brand d-md-none" to={`/`}>
                    <img src="//content.nanini.be/wp-content/uploads/2017/04/Logo-Nanini-transparant.png" className="nav-logo" alt={siteTitle} />
                  </Link>
                  <button className="navbar-toggler" onClick={e => this.onToggle(e)}>
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className={`collapse navbar-collapse ${this.state.open ? 'show' : ''}`}>
                    <ul className="navbar-nav mx-auto">
                      <li className="nav-item">
                        <Link className="nav-link" to="/over-ons">Over ons</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/ons-aanbod">Aanbod</Link>
                      </li>
                      {data.site.siteMetadata.options.eshop && data.site.siteMetadata.options.vouchers ? (
                        <li className="nav-item mr-3">
                          <Link className="nav-link" to="/cadeaubon">Cadeaubon</Link>
                        </li>
                      ) : ''}
                      <li className="nav-item d-none d-md-block">
                        <Link className="navbar-brand mx-2" to="/">
                          <img src="//content.nanini.be/wp-content/uploads/2017/04/Logo-Nanini-transparant.png" className="nav-logo" alt={siteTitle} />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/blog">Blog</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/nieuws">Nieuws</Link>
                      </li>
                      {data.site.siteMetadata.options.eshop ? (
                        <li className="nav-item">
                          <Link className="nav-link" to="/cart">Mandje ({ cart ? cart.items.length : 0 })</Link>
                        </li>
                      ) : ''}
                    </ul>
                  </div>
                </nav>
              )
            }}
          </CartContext.Consumer>
        )}
      />
    )
  }
}

Header.propTypes = {
  toggleDisplay: PropTypes.bool,
  display: PropTypes.bool,
}

export default Header
