import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import "../styles/base.scss"
import "../styles/colors.scss"

class Layout extends Component {
  state = {
    aboveTreshold: false
  }

  handleScroll(e, l) {
    if (!l.state.aboveTreshold && window.pageYOffset > 250) {
      this.setState({ aboveTreshold: true })
    }
    else if (l.state.aboveTreshold && window.pageYOffset <= 250) {
      this.setState({ aboveTreshold: false })
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', e => this.handleScroll(e, this))
    }
  }

  render() {
    const { children, toggleTopNav } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <Header siteTitle={data.site.siteMetadata.title} toggleDisplay={toggleTopNav} display={toggleTopNav ? this.state.aboveTreshold : true} />
            <section className="mt-5 pt-5">
              <div className={`container mt-2 ${toggleTopNav && this.state.aboveTreshold ? 'treshold' : ''}`}>
                {children}
              </div>
            </section>
            <Footer />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  toggleTopNav: PropTypes.bool,
}

export default Layout
