import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              wordpress_id
              name
              slug
              count
              items {
                object_id
                order
                title
                url
                object_slug
              }
            }
          }
        }
      }
    `}
    render={data => (
      <footer className="">
        <div className="container">

          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="card card-bg-primary card-bg-no-border eqheight">
                <div className="card-body">
                  <h3 className="pb-1">Openingsuren</h3>
                  <h4>Nanini</h4>
                  <p>ma-zat 10u00 - 18u00<br />Gesloten op zon- &amp; feestdagen</p>

                  <h4 className="mt-5">Nanini Outlet</h4>
                  <p>woe-zat 10u00 - 18u00<br />Gesloten op ma, di, zon- &amp; feestdagen</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-6">
              <div className="card card-bg-primary card-bg-no-border eqheight">
                <div className="card-body">
                  <h3 className="pb-1">Over Nanini</h3>
                  <div className="row">
                    <div className="col-sm-6">
                      <h4>Nanini</h4>
                      <p>Grote Markt 67<br />2300 Turnhout<br />Tel.:+32 (0) 14 42 22 24</p>
                      <h4>Nanini Outlet</h4>
                      <p>Passage Groenendaal<br />2300 Turnhout</p>
                    </div>
                    <div className="col-sm-6">
                      {data.allWordpressWpApiMenusMenusItems.edges.filter(o => o.node.slug === 'eshop_footer') ? (
                        <ul className="list-unstyled">
                          {data.allWordpressWpApiMenusMenusItems.edges.filter(o => o.node.slug === 'eshop_footer').shift().node.items.map(item => (
                            <li className="" key={item.object_id}>
                              <Link to={`/${item.object_slug}`} className="footer-link">{item.title}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="card card-bg-primary card-bg-no-border eqheight">
                <div className="card-body">
                  <h3 className="pb-1">Volg ons op</h3>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/nanini.turnhout" className="" target="_blank" rel="noopener noreferrer">
                        <FaFacebookSquare className="fa fa-social" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/nanini.turnhout/" className="" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="fa fa-social" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
    )}
  />
)

export default Footer
